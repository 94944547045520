@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: "futura-pt", sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	background-image: url('../../dist/img/bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	min-height: 100vh;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

h1,h2,h3,h4,h5,p,li,ul,ol{
	margin: 0;
}

.split{
	@include flex(column,initial,initial);
	.side{
		flex: 1;
	}
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.content{
		position: relative;
	}
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.sr{
	display: none;
}

.social{
	list-style: none;
	padding: 0;
	@include flex(row,center,center);
	gap: 20px;
	font-size: 25px;
	&.blue{
		li{
			a{
				color: $blue;
				@include hover-focus{
					color: $white;
				}
			}
		}
	}
}

.btn{
	font-size: 14px;
	text-transform: uppercase;
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	font-style: italic;
	font-weight: 700;
	padding: 10px 40px;
	display: inline-block;
	&.btn-white{
		background-color: $white;
		color: $pink;
		@include hover-focus{
			color: $blue;
		}
	}
}

.tickets-btn{
	img{
		width: 170px;
	}
}

.bg-gray{
	position: relative;
	&::before{
		content: "";
		background-image: url('../../dist/img/bg-gray.jpg');
		background-size: contain;
		background-position: center;
		background-repeat: repeat;
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: .7;
	}
	.content{
		position: relative;
	}
}

.vendors{
	@include flex(row,center,center);
	gap: 20px;
	max-width: 230px;
	a{
		img{
			width: 30px;
		}
	}
	@include tablet-up{
		gap: 10px;
		a{
			img{
				width: 40px;
			}
		}
	}
}

header {
	padding: 30px 0;
	.container{
		>.split{
			.side{
				.menu-trigger{
					order: 2;
					display: block;
					border: none;
					background-color: transparent;
					border: none;
					border-style: none;
					border: solid 2px transparent;
					border-top-color: white;
					border-bottom-color: white;
					position: absolute;
					top: 10px;
					right: 10px;
					width: 40px;
					height: 20px;
					transition: all .4s;
					z-index: 99999;
					&:before,&::after{
						content: '';
						display: block;
						width: 100%;
						height: 2px;
						top: calc(50% - 1px);
						left: 0;
						position: absolute;
						background-color: white;
						transition: all .4s;
					}
					&.active{
						border-color: transparent;
						position: fixed;
						&::after{
							transform: rotate(45deg);
						}
						&::before{
							transform: rotate(-45deg);
						}
					}
				}
				.menu{
					@include flex(column,center,center);
					position: fixed;
					width: 100%;
					height: 100vh;
					top: 0;
					left: 0;
					background-color: hsla(0, 0%, 44%, 0.90);
					z-index: 9999;
					opacity: 0;
					pointer-events: none;
					transition: all .4s;
					a{
						font-size: 22px;
						text-transform: uppercase;
						font-style: italic;
						font-weight: 700;
						color: $white;
						padding: 20px;
					}
					&.active{
						opacity: 1;
						pointer-events: all;
					}
				}
				&:nth-child(1){
					order: 3;
				}
				&:nth-child(2){
					order: 1;
				}
				&:nth-child(3){
					order: 2;
					@include flex(column,center,center);
					text-align: center;
					.split{
						gap: 20px;
					}
				}
			}
		}
	}

	@include tablet-up{
		.container{
			>.split{
				align-items: center;
				justify-content: space-between;
				.side{
					max-width: max-content;
					&:nth-child(1){
						order: 1;
					}
					&:nth-child(2){
						order: 2;
						.header-logo{
							height: 130px;
							display: block;
							margin: 0 auto;
						}
					}
					&:nth-child(3){
						order: 3;
						@include flex(row,flex-end,center);
					}
					.menu{
						@include flex(row,flex-start,center);
						gap: 30px;
						opacity: 1;
						pointer-events: all;
						position: unset;
						width: unset;
						height: unset;
						background-color: transparent;
						a{
							padding: 0;
							border-bottom: solid 3px transparent;
							@include hover-focus{
								border-bottom-color: $sand;
							}
						}
					}
					.split{
						gap: 40px;
						align-items: center;
						justify-content: flex-end;
					}
				}
			}
		}
	}


}

main {

	#hero{
		img{
			width: 100%;
			height: auto;
		}
	}

	#music{
		overflow: hidden;
		padding: 30px 0;
		.container{
			.wrapper{
				padding: 20px;
				.split{
					@include flex(column-reverse,initial,initial);
					gap: 30px;
					.side{
						@include flex(column,center,center);
						gap: 20px;
						text-align: center;
						.music-logo{
							width: 100%;
							max-width: 230px;
						}
						h3{
							font-weight: 700;
							text-align: center;
							font-style: italic;
							text-transform: uppercase;
							color: $blue;
							font-size: 30px;
						}
						h4{
							text-transform: uppercase;
							font-weight: 400;
							font-size: 22px;
						}
						p{
							text-align: center;
							text-transform: uppercase;
							color: $white;
							font-size: 18px;
						}
						>img{
							width: 100%;
						}
					}
				}
			}
		}
		@include tablet-up{
			padding: 60px 0;
			.container{
				.wrapper{
					padding: 100px;
					.split{
						@include flex(row,initial,initial);
						.side{
							&:first-child{
								max-width: 400px;
							}
							h3{
								font-size: 40px;
								max-width: 300px;
							}
							h4{
								font-size: 30px;
							}
						}
					}
				}
			}
		}
	}

	#videos{
		overflow: hidden;
		padding: 30px 0;
		.container{
			max-width: 100%;
			padding: 0;
			h2{
				text-align: center;
				font-size: 34px;
				margin-bottom: 30px;
				text-transform: uppercase;
				font-style: italic;
			}
			.slider-videos{
				padding: 0;
				.slick-list {
					margin-left: -20px;
					.slick-slide {
						margin-left: 20px;
					}
				}
				.slick-arrow{
					color: $white;
				}
				.video{
					position: relative;
					width: 100%;
					aspect-ratio: 6/4;
					display: block;
					cursor: pointer;
					img{
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						object-fit: cover;
						z-index: 1;
					}
					iframe{
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						object-fit: cover;
						z-index: 2;
						opacity: 0;
						pointer-events: none;
						transition: all .4s;
						&.active{
							opacity: 1;
							pointer-events: all;
						}
					}
				}
			}
		}
		@include tablet-up{
			padding: 100px 0;
			.container{
				h2{
					font-size: 70px;
					margin-bottom: 40px;
				}
				.slider-videos{
					.slick-arrow{
						font-size: 60px;
						top: calc(50% - 30px);
					}
					.slick-list {
						margin-left: -60px;
						.slick-slide {
							margin-left: 60px;
						}
					}
					.slick-slide{
						img,iframe{
							height: calc(100%-60px);
							top: 30px;
							transition: all .4s;
							opacity: .64;
						}
						&.slick-center{
							img,iframe{
								height: 100%;
								top: 0;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	#tour{
		padding: 40px 0;
		.container{
			.wrapper{
				.content{
					padding: 20px;
					h2{
						text-align: center;
						font-size: 34px;
						margin-bottom: 30px;
						text-transform: uppercase;
						font-style: italic;
					}
					#events{
						.event{
							border-bottom: solid 1px $pink;
							padding: 20px 0;
							@include flex(column,center,center);
							gap: 5px;
							text-align: center;
							text-transform: uppercase;
							.event-date{
								font-weight: 700;
							}
						}
					}
				}
			}
		}

		@include tablet-up{
			padding: 100px 0;
			.container{
				.wrapper{
					.content{
						padding: 80px 40px;
						h2{
							font-size: 70px;
							margin-bottom: 40px;
						}
						#events{
							.event{
								@include flex(row,space-between,flex-end);
								text-align: left;
								font-size: 15px;
								>div{
									flex: 1;
									.event-date{
										margin-bottom: 10px
									}
									&.event-links{
										@include flex(row,flex-end,initial);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	#about{
		padding: 40px 0;
		.container{
			.split{
				gap: 20px;
				.side{
					h2{
						text-align: center;
						font-size: 34px;
						margin-bottom: 30px;
						text-transform: uppercase;
						font-style: italic;
						text-align: left;
					}
					p{
						line-height: 1.6;
					}
					&:last-child{
						padding: 20px;
					}
				}
			}
		}
		@include tablet-up{
			padding: 100px 0;
			.container{
				.split{
					.side{
						h2{
							font-size: 70px;
							margin-bottom: 40px;
						}
						&:last-child{
							@include flex(column,center,flex-start);
							padding: 40px;
						}
					}
				}
			}
		}
	}

	#instagram{
		padding: 40px 0;
		@include tablet-up{
			padding: 100px 0;
		}
	}

}

footer {
	padding: 20px 0;
	.container{
		.split{
			align-items: center;
			margin-bottom: 30px;
			gap: 30px;
		}
		.copyright{
			p{
				font-size: 10px;
				text-align: center;
				line-height: 1.4;
			}
		}
	}

	@include tablet-up{
		padding: 40px 0;
		.container{
			.split{
				justify-content: center;
				align-items: center;
				margin-bottom: 20px;
				.side{
					max-width: max-content;
				}
			}
		}
	}

}

.popup{
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: hsla(0, 0%, 44%, 0.90);
	top: 0;
	left: 0;
	transition: all .4s;
	opacity: 0;
	pointer-events: none;
	&.active{
		opacity: 1;
		pointer-events: all;
	}
	.container{
		height: 100vh;
		@include flex(column,center,center);
		.wrapper{
			background-color: $white;
			padding: 50px 20px 20px;
			position: relative;
			width: 100%;
			max-width: 600px;
			color: $black;
			.close{
				width: 40px;
				height: 40px;
				background-color: $black;
				color: $white;
				padding: 0;
				@include flex(column,center,center);
				border: none;
				position: absolute;
				top: 0;
				right: 0;
				i{
					font-size: 20px;
				}
			}
		}
	}
}

.inputs-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	input {
			border-bottom: 1px solid $black;
			color: $black;
			flex-basis: 49%;

			font-size: rem(14);
			letter-spacing: .4em;
			margin-top: rem(10);
			padding: rem(20) 0;
			text-align: center;
			text-transform: uppercase;

			&::placeholder {
					color: $black;
			}

			@media screen and (max-width: 600px) {
					flex-basis: 100%;
			}
	}

	.wrap {
			flex-basis: 100%;
			margin-top: rem(30);
			text-align: center;

			button {
					background-color: $black;
					color: $white;
					display: inline-block;
					font-size: rem(15);
					font-weight: bold;
					padding: rem(15) rem(25);
					text-transform: uppercase;
					@include hover-focus {
							background-color: $black;
							color: $white;
					}
			}
	}
}

.note {
	font-size: rem(10);
	letter-spacing: .1em;
	margin: rem(15) 0 0;
	text-transform: uppercase;
}

.slick-slider{
	padding: 0 30px;
	.slick-arrow{
		position: absolute;
		top: calc(50% - 25px);
		font-size: 30px;
		background-color: transparent;
		color: $pink;
		border: none;
		padding: 0;
		cursor: pointer;
		z-index: 99;
		&.slick-prev{
			left: 0;
		}
		&.slick-next{
			right: 0;
		}
	}
}

#feed {
	display: flex;
	width: 100%;
	margin: 0 auto;
	flex-flow: row wrap;
	position: relative;
	z-index: 1;

	> div {
		width: 25%;
		padding: 1%;

		@media screen and (max-width: 800px) {
			flex-basis: 50%;
			&:nth-last-child(2), &:last-child{
				display: none;
			}
		}

		a {
			background-repeat: no-repeat;
			background-size: cover;
			display: block;
			overflow: hidden;
			padding-bottom: 100%;
			position: relative;
			width: 100%;

			span {
				align-items: center;
				background-color: rgba(0, 0, 0, .7);
				color: $white;
				display: flex;
				font-weight: 300;
				justify-content: center;
				line-height: 1.3;
				padding: rem(15);
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				transform: translateX(-100%);
				transition: transform .3s;
			}

			&:active,
			&:focus,
			&:hover {
				span {
					transform: translateX(0);
				}
			}
		}
	}
}